body {
  margin: 30px 0px 0px ;
  font-family: Montserrat-Medium;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #1C3664;
  background-color: #FEFAF6;
  overflow-x: hidden;
}

/* @media (max-width:1280px) {
  body {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-left: 115px;
  }
} */

a {
  text-decoration: none;
  color: inherit; 
  border: none; 
  outline: none; 
  background: none; 
  font: inherit; 
  cursor: inherit;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 6px; 
  }


::-webkit-scrollbar-track {
  background: #FEFAF6; 
}


::-webkit-scrollbar-thumb {
  background: #1C3664; 
  border-radius: 5px;
}


::-webkit-scrollbar-thumb:hover {
  background: #1C3664; 
}

@font-face {
  font-family: Montserrat-Medium;
  src: url('./fonts/Montserrat-Medium.ttf');
}

@font-face {
  font-family: Montserrat-Bold;
  src: url('./fonts/Montserrat-Bold.ttf');
}

@font-face {
  font-family: Montserrat-Regular;
  src: url('./fonts/Montserrat-Regular.ttf');
}

@font-face {
  font-family: SigmarOne-Regular;
  src: url('./fonts/SigmarOne-Regular.ttf');
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.logo-block {
  display: flex;
}

.logo-block img {
  height: 50px;
}

.header-items {
  display: flex;
  justify-content: end;
  text-align: end;
  flex-grow: 1;
  padding-right: 50px;
}

.header-items p {
  margin: 0 15px;
  cursor: pointer;
  font-size: 18px;
}

.header-items p:hover {
  color: #FFA800;
  text-decoration: underline;
}

.join-us {
  background-color: #FFA800;
  color: #040406;
  border-radius: 40px;
  border: none;
  padding: 15px 25px;
  font-family: Montserrat-Bold;
  font-size: 18px;
  cursor: pointer;
  width: 130px;
  height: 55px;
}

.join-us a {
  color: inherit;
  text-decoration: none;
}

.join-us:hover {
  background-color: #FFFFFF;
  color: #1C3664;
  border: 3px solid #FFA800;
}

@media (max-width: 1280px) {
  .header-items {
    display: none;
  }
  .join-us {
    display: none;
  }
  .logo-block {
    width: 93%;
    padding: 20px 30px 0px 40px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin: 0px auto;
    text-align: center;
    position: fixed;
    left: 50%;
    top: 0px;
    transform: translateX(-50%);
    background-color: #FEFAF6;
    z-index: 100;
  }
  

  .logo-block img {
    order: 1;
    width: 14%;
    height: 14%;
  }

  .logo-block svg {
    width: 14%;
    height: 14%;
    order: 2;
  }
}

.mobile-menu {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.mobile-menu-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 1rem;
}

.close-menu {
  background: none;
  border: 1px solid red;
  font-size: 1.5rem;
  cursor: pointer;
}

.mobile-nav {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mobile-nav p {
  margin: 2rem 0;
  font-size: 3rem;
  cursor: pointer;
}

@media (min-width: 1290px) {
  .logo-block svg {
    display: none;
  }
}

/* Component Main */

@media  (max-width: 1280px){
  .sidebar img {
    display: none;
  }

  .sidebar2 img {
    display: none;
  }
  .main {
    margin: 150px 0px;
    font-size: 30px;
  }
}

.content {
  display: flex;
  justify-content: space-around;
  margin: 50px 0px;
}

.sidebar {
  display: flex;
  align-items: center;
}

.sidebar2 {
  display: flex;
  align-items: end;
}

.main {
  text-align: center;
  max-width: 576px;
  height: 380px;
  margin-top: 100px;
}

.orange {
  color: #FFA800;
  font-family: SigmarOne-Regular;
}

.blue {
  color: #4D80C1;
  font-family: SigmarOne-Regular;
}

.green {
  color: #71B561;
  font-family: SigmarOne-Regular;
}

.red {
  color: #DB5245;
  font-family: SigmarOne-Regular;
}

.join-us-main{
  width: 280px;
  height: 100px;
  background-color: #FFA800;
  color: #040406;
  border-radius: 60.6px;
  text-align: center;
  border: none;
  font-family: Montserrat-Bold;
  font-size: 36.6px;
  margin-top: 50px;
}

.join-us-main:active{
  border: 3px solid #FFA800;
  background-color: #FFFFFF;
}

.join-us-main:hover{
  border: 3px solid #FFA800;
  background-color: #FFFFFF;
  cursor: pointer;
  color: #1C3664;
}

.title{
  font-family: SigmarOne-Regular;
}

.page-title{
  font-family: Montserrat-Regular;
  font-size: 22px;
}

/* About */

@media (min-width: 1280px) {
  .see-more-page {
  display: none;
}
}

@media (max-width: 1280px) {
  .presentation-file {
    display: none;
    border: none;
  }
  .presentation {
    display: none;
    border: none;
  }
  .see-more-page {
    text-align: center;
    margin-top: 20px;
    text-decoration: underline;
    text-decoration: underline;
    text-align: center;
  }
  .h2-about {
    text-align: center;
  }
   .block-about .page-about {
    width: 600px;
    height: 400px;
    margin-top: 100px;
    text-align: center;
    font-size: 35px;
    margin-bottom: 200px;
    font-weight: 800;
  }
}


.h2-about{
  text-align: center;
  font-size: 40px;
  font-family: Montserrat-Regular;
  margin-top: 100px;
}

.page-about{
  max-width: 806px;
  height: 184px;
  text-align: center;
  margin: 0 auto;
  font-size: 25px;
  font-family: Montserrat-Regular;
}

.block-about{
  width: 90%;
  margin: 100px auto;
}

.presentation-file {
  border-radius: 20px;
  width: 1100px;
  height: 600px;
  border: 10px solid #FFA800;
  display: flex;
  margin: 100px auto;
  border-radius: 30px;
}

/* History */

.team-history {
  display: block;
  margin: 0 auto;
}

.h2-history{
  text-align: center;
  font-size: 40px;
}

.page{
  text-align: center;
  max-width: 877px;
  height: 60px;
  font-size: 25px;
  font-family: Montserrat-Regular;
  margin: 0 auto;
}

.blocks {
  display: flex;
  justify-content: space-around;
  width: 90%;
  margin: 100px auto;
}

.image-container {
  position: relative;
  display: inline-block; 
}

.block1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  align-items: center;
  align-content: center;
  padding: 20px;
  border-radius: 10px;
}

.block2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  align-items: center;
  align-content: center;
  padding: 20px;
  border-radius: 10px;
}

.block3 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  align-items: center;
  align-content: center;
  padding: 20px;
  border-radius: 10px;
}

.page-info {
  width: 103px;
  height: 52px;
  margin: 0 auto;
}

.page-history{
  text-align: center;
  max-width: 1046px;
  height: 30px;
  font-size: 25px;
  font-family: Montserrat-Regular;
  margin: 40px 0px 0px 50px;
}

.page-facebook{
  text-align: center;
  height: 30px;
  font-size: 25px;
  font-family: Montserrat-Regular;
  margin: 6px 0px 0px 60px;
  max-width: 1100px;
}

.link-facebook{
  color: #1C3664;
  text-decoration: underline;
  cursor: pointer;
}

.link-facebook:hover {
  color: #FFA800;
  cursor: pointer;
  text-decoration: underline;
}

.block-history{
  border-bottom: 1px solid #FFFFFF;
  width: 80%;
  margin: 100px auto;
}

@media (max-width: 1280px) {
  .blocks {
    display: block;
    width: 300px;
    /* margin-left: 250px; */
  }
  .link-facebook {
    font-size: 30px;
  }
  .page-history {
    font-size: 35px;
  }
  .image-container {
    margin-top: 20px;
  }
  .team-history {
    display: none
  }
  .block1 {
    font-weight: 900;
    margin-top: -20px;
  }
  .block2 {
    font-weight: 900;
    margin-top: -20px;
  }
  .block3 {
    font-weight: 900;
    margin-top: -20px;
  }
  .page-info {
    margin-top: -20px;
    font-size: 20px;
    width: 150px;
  }
  .page-facebook {
    margin-top: 130px;
    font-size: 30px;
    font-weight: 900;
  }
  .h2-history {
    font-size: 45px;
  }
  .page {
    font-size: 35px;
  }
}

/* Program */

.block-program{
  border-bottom: 1px solid #FFFFFF;
  width: 80%;
  margin: 0px auto;
}

.h2-program{
  margin: 0 auto;
  text-align: center;
  font-size: 40px;
}

.page-program {
  margin: 0 auto;
  text-align: center;
  max-width: 829px;
  height: 60px;
  text-align: center;
  font-family: Montserrat-Regular;
  margin: 20px auto;
  font-size: 25px;
}

.learn-more{
  display: flex;
  justify-content: space-around;
}

.program{
  display: flex;
  justify-content: space-around;
  margin-top: 100px;
  margin-bottom: 100px;
}

.info-learn-block{
  display: flex;
  justify-content: space-between;
  width: 100px;
  text-align: center;
  align-items: center;
}

.item-block{
  background-color: #FFFFFF;
  border-radius: 20px;
  border: 1px solid #1C3664;
}

.item-block2{
  background-color: #FFFFFF;
  border-radius: 20px;
  border: 1px solid #1C3664;
}

.button-program{
  width: 155px;
  height: 53px;
  border-radius: 12px;
  background-color: #1C3664;
  color: #FFFFFF;
  font-size: 18px;
  border: none;
  font-family: Montserrat-Bold;
  cursor: pointer;
}

.button-program:hover {
  background-color: #FFA800;
}

.title-program{
  color: #1C3664;
  font-size: 30px;
  width: 90%;
  margin: 0 auto;
}

.group-program{
  color: #FFA800;
  width: 60%;
  margin: 0 auto;
}

.time-program{
  color: #1C3664;
}

@media (max-width: 1280px) {
  .program {
    display: block;
    width: 570px;
    margin: 0 auto;
  }
  .program img {
    width: 100%;
  }
  .item-block {
    margin-bottom: 100px;
  }
  .block-program {
    margin: 0 auto;
  }
  .page-program {
    margin: 100px auto;
    font-size: 35px;
    text-align: center;
    font-weight: 500;
  }
  .h2-program {
    margin: 0 auto;
    margin-top: 120px;
    font-size: 45px;
  }
  .button-program {
    margin-top: -30px;
    margin-right: -70px;
    width: 200px;
    font-size: 23px;
    height: 70px;
  }
}

/* Gallery  component*/
.slider {
  display: flex;
  justify-content: space-evenly;
}

.slide {
  overflow: hidden;
  border-radius: 100%;
}

.slide img {
  width: 100%;
  height: 100%;
  transition: transform 0.3s ease; 
}

.slide img:hover {
  transform: scale(1.3);
}


.buttons {
  margin: 50px auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 400px;
}

.title-gallery {
  font-size: 40px;
  text-align: center;
  font-family: Montserrat-Bold;
}

.page-gallery {
  font-size: 25px;
  text-align: center;
  font-family: Montserrat-Regular;
}

.block-gallery { 
  border-bottom: 1px solid #FFFFFF;
  width: 80%;
  margin: 0px auto;
}

@media (max-width: 1280px) {
  .title-gallery {
    margin: 200px 0px 0px 0px;
    font-size: 45px;
  }
  .page-gallery {
    font-size: 30px;
  }
  .buttons svg {
    width: 100px;
    height: 100px;
  }
  .slider {
    width: 230%;
    margin-top: 100px;
    height: 450px;
    margin-left: -460px;
  }
}

/* FQA Component */

.title-fqa {
  font-size: 40px;
  text-align: center;
  margin: 30px auto;
  color: #1C3664;
}

.block-fqa {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  width: 860px;
  border-radius: 20px;
  border: 1px solid #1C3664;
  margin: 20px auto;
  text-align: left;
  cursor: pointer;
  transition: height 0.3s ease; 
  overflow: hidden; 
  background-color: #FFFFFF;
  color: #1C3664;
  position: relative;
}

.block-fqa .fqa-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.block-fqa h3 {
  margin: 0;
  font-size: 30px;
  flex-grow: 1;
}

.block-fqa p {
  margin: 10px 0 0 0;
  padding-top: 20px;
  border-top: 1px solid #FFA800; 
  color: #fff;
}

.fqa-button {
  width: 30px;
  height: 30px;
  cursor: pointer;
  transform: rotate(0deg);
  transition: transform 0.3s ease;
}

.block-fqa.expanded .fqa-button {
  transform: rotate(180deg);
}

.block-main {
  border-bottom: 1px solid #FFFFFF;
  width: 100%;
  margin: 0 auto;
}

@media (max-width: 1280px) {
  .block-fqa {
    width: 730px;
    font-size: 24px;
  }
  .title-fqa {
    font-size: 20px;
  }
  .fqa-button {
    width: 60px;
    height: 60px;
  }
}

/* Team component  */

.team {
  display: flex;
  flex-wrap: wrap; 
  width: 100%;
  justify-content: center;
}

.team-card {
  width: 24%; 
  box-sizing: border-box;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 50px 0px;
}

.name-person {
  width: 160px;
  height: 34px;
}

.job-title{
  width: 130px;
  height: 50px;
}

.title-team {
  margin: 100px auto 50px;
  text-align: center;
  font-size: 40px;
}

.page-team {
  margin: 20px auto;
  text-align: center;
  font-size: 25px;
}

@media (max-width: 1280px) {
  .team {
    display: block;
    width: 100%;
  }

  .imteam {
    width: 44%;
    height: 44%;
  }

  .team-card {
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin: 50px auto;
  }
  .main-team {
    margin: 0 auto;
    max-width: 850px;
  }
  .info-card {
    width: 396px;
    text-align: start;
    font-size: 25px;
  }
  .info-card svg {
    width: 70px;
    height: 70px;
  }
  .name-person {
    align-items: start;
    text-align: start;
    width: 100%;
    height: 30px;
    font-size: 28px;
  }
  .job-title {
    align-items: start;
    text-align: start;
    width: 100%;
    font-size: 24px;
    height: 30px;
  }
  .page-team {
    font-size: 27px;
    width: 100%;
  }
}

/* Supported component */

.title-support {
  margin-top: 50px;
  margin-bottom: 50px;
  text-align: center;
  font-size: 40px;
}

.support {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 0 auto;
  height: auto;
  width: 840px;
  gap: 20px;
}

.support-item {
  width: 246px;
  height: 146px;
  background-color: #FFFFFF;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px; 
  border: 1px solid #1C3664;
}

.support-block {
  border-bottom: 1px solid #FFFFFF;
  border-top: 1px solid #FFFFFF;
  width: 80%;
  margin: 100px auto;
}

@media (max-width:1280px) {
  .support {
    text-align: center;
    width: 87.1%;
  }
  .title-support {
    font-size: 45px;
  }
  .support-block {
    width: 100%;
  }
}

/* Contact component */

.twinschool-form {
  display: flex;
  justify-content: space-around;
}

.title-contact{ 
  text-decoration: underline;
}

.email-company {
  text-decoration: underline;
}

.plane {
  display: flex;
  justify-content: end;
  align-items: center;
  margin-bottom: 20px;
}

.user-info {
  display: flex;
  width: 579px;
  justify-content: space-between;
}

.contact{
  text-align: center;
  font-size: 40px;
}

.block-first-name {
   width: 275px;
   height: 65px;
   border: 1px solid #1C3664;
   border-radius: 12px;
   display: flex;
   justify-content: space-around;
   align-items: center;
}

.block-last-name {
  width: 275px;
  height: 65px;
  border: 1px solid #1C3664;
  border-radius: 12px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.block-email {
  width: 577.41px;
  margin-top: 20px;
  height: 65px;
  border: 1px solid #1C3664;
  border-radius: 12px;
  display: flex;
  justify-content: start;
  align-items: center;
}

.block-email-info {
  display: flex;
  justify-content: space-between;
  width: 232px;
  padding-left: 23px;
  align-items: center;
}

input:focus {
  outline: none; 
  border-color: #1C3664; 
  box-shadow: none;
}

input {
  border: none;
  background-color: #FEFAF6;
  color: #1C3664;
  outline: none;
  box-shadow: none;
}

input:active {
  border: none;
  background-color: #FEFAF6;
  color: #1C3664;
  outline: none;
  box-shadow: none;
}

input:focus {
  border: none;
  background-color: #FEFAF6;
  color: #1C3664;
  outline: none;
  box-shadow: none;
}

::placeholder {
  color: #1C3664;
}

.describe {
  width: 552px;
  height: 148px;
  background-color: #FEFAF6;
  border: 1px solid #1C3664;
  border-radius: 12px;
  color: #1C3664;
  padding-left: 23px;
  padding-top: 20px;
}

.describe:focus {
  outline: none; 
  border-color: transparent; 
  box-shadow: none;
  border: 1px solid #1C3664;
}

.submit {
  width: 134.65px;
  height: 61.38px;
  border-radius: 13.91px;
  background-color: #1C3664;
  color: #FFFFFF;
  font-size: 20.87px;
  font-family: Montserrat-Bold;
  border: none;
  cursor: pointer;
}

.submit:hover {
  background-color: #FFA800;
}

.block-submit {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.footer-logo {
  text-align: center;
  margin-top: 20px;
}

.block-contact {
  border-bottom: 1px solid #FFFFFF;
  border-top: 1px solid #FFFFFF;
  width: 80%;
  margin: 100px auto;
}

textarea {
  font-family: Montserrat-Regular;
}

.logo-footer{
  cursor: pointer;
}

.input-container {
  position: relative;
}

input {
  padding: 10px;
  font-size: 16px;
  border-radius: 4px;
  outline: none;
}

input:focus + .placeholder,
input:not(:placeholder-shown) + .placeholder {
  transform: translateY(-20px);
  font-size: 12px;
  font-weight: bold;
  color: #1C3664;
}

.placeholder {
  position: absolute;
  left: 10px;
  top: 10px;
  transition: all 0.2s ease;
  pointer-events: none;
  font-size: 16px;
  color: #1C3664;
}

.page-contact-question {
  color: #FFA800;
}


@media (max-width:1280px) {
  .twinschool-form {
    display: block;
  }
  .popup-inner {
    width: 130%;
    height: 700px;
  }
  .describe {
   margin: 0 auto;
   width: 96%;
  }
  .page-contact-question {
    font-size: 25px;
    width: 100%;
    margin: 0 auto;
  }
  .block-submit {
    display: flex;
    justify-content: center;
  }
  .footer-logo {
    margin-top: 100px;
  }
  .contact {
    margin: 100px auto;
    font-size: 45px;
  }
  .form {
    margin: 0 auto;
  }
  .placeholder {
    font-size: 20px;
  }
  ::placeholder {
    font-size: 16px;
  }
  .twinschool-form {
    display: flex;
    flex-direction: column; 
}



.bbb {
    order: 2;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 40px auto;
    width: 100%;
    font-size: 19px;
    text-align: center;
}

.form {
    order: 1;
}
.plane img{
  display: none;
}
.user-info {
  display: block;
  margin-top: 20px;
  width: 97.5%;
}
.block-last-name {
  margin-top: 20px;
  width: 100%;
  justify-content: start;
  padding-left: 10px;
}
.block-first-name {
  margin-top: 20px;
  width: 100%;
  justify-content: start;
  padding-left: 10px;
}
.div2 {
  text-align: center;
  font-size: 35px;
}
.block-email{
  width: 99%;
} 
.email {
  width: 150%;
}
}

.email {
  width: 150%;
}

.social-icon:hover {
  stroke: #FFA800;
  fill: #FFA800;
}

.social-svg:hover {
  stroke: #FFA800;
  fill: #FFA800;
}

.icon-path {
  stroke: #1C3664;
  fill: #1C3664;
}



@media (max-width: 1280px) {
  .div3 {
    display: none;
  }
  .form {
    display: none;
  }
}

@media (min-width: 1280px) {
  .div3 {
    display: none;
  }
  
.form2 {
  display: none;
}
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-inner {
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 404px;
  height: 225px;
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  border: 2px solid #1C3664;
}

.popup-inner h2 {
  margin-bottom: 10px;
  color: #FFA800;
}

.butt-pop-up {
  text-align: end;
}

.butt-pop-up svg {
  cursor: pointer;
}

.popup-inner p {
  margin-bottom: 20px;
}






